import { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Button, Stack, Typography, CircularProgress, Card, Box } from '@mui/material'
import { useValidateResetTokenMutation, useResetPasswordMutation } from './api'
import { enqueueSnackbar } from 'notistack'
import { PasswordField } from '../../components/PasswordField'

export function ResetPassword() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [token, setToken] = useState<string | null>(null)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState<boolean>(true)

  const [validateResetToken] = useValidateResetTokenMutation()
  const [resetPassword] = useResetPasswordMutation()

  useEffect(() => {
    const tokenParam = searchParams.get('token')
    if (tokenParam) {
      validateResetToken({ variables: { token: tokenParam } })
        .then(() => {
          setToken(tokenParam)
        })
        .catch(() => enqueueSnackbar('Token inválido ou expirado.', { variant: 'error' }))
        .finally(() => setLoading(false))
    } else {
      enqueueSnackbar('Token não fornecido.', { variant: 'error' })
      setLoading(false)
    }
  }, [searchParams, validateResetToken])

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      enqueueSnackbar('As senhas não coincidem.', { variant: 'error' })
      return
    }

    setLoading(true)
    try {
      await resetPassword({ variables: { token: token as string, newPassword: password } })
      enqueueSnackbar('Senha redefinida com sucesso! Você será redirecionado...', { variant: 'success' })
      setTimeout(() => navigate('/login'), 3000)
    } catch {
      enqueueSnackbar('Falha ao redefinir a senha. Tente novamente.', { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return (
      <Stack justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Stack>
    )
  }

  return (
    <Stack justifyContent="center" alignItems="center" height="100vh">
      <Card sx={{ p: 3, width: { xs: '90%', sm: '400px' } }}>
        <Typography variant="h5" textAlign="center" mb={2}>
          Redefinir Senha
        </Typography>
        <Box mb={2}>
          <PasswordField
            id='password'
            label='Nova Senha'
            variant='outlined'
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
            fullWidth
            showRequirements
          />
        </Box>
        <Box mb={2}>
          <PasswordField
            id='confirm-password'
            label='Confirmar Nova Senha'
            variant='outlined'
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            required
            fullWidth
            showRequirements
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Alterar Senha'}
        </Button>
      </Card>
    </Stack>
  )
}
