import { ReactNode } from 'react'

import { AccountCircle, Logout, Settings } from '@mui/icons-material'
import MenuIcon from '@mui/icons-material/Menu'
import { ListItem, ListItemButton, ListItemText, Menu, MenuItem, Paper, Stack, useMediaQuery } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemIcon from '@mui/material/ListItemIcon'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { CSSObject, Theme, styled, useTheme } from '@mui/material/styles'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../auth/AuthProvider'
import { ClientSelect } from '../../components/clients-select/ClientsSelect'
import { mainMenu } from './MainMenu'

type AppMenuProps = {
  children: ReactNode
}

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
})

const closedMixin = (theme: Theme): CSSObject => ({
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
)

export function AppMenu(props: AppMenuProps) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { user, logout } = useAuth()
  const isTherapist = user?.isTherapist

  const [defaultMenuStatus, setDefaultMenuStatus] = useDefaultMenuStatus()
  const [menuOpen, setMenuOpen] = useState(defaultMenuStatus)
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<null | HTMLElement>(null)

  const navigate = useNavigate()

  const toggleDrawer = () => {
    const newValue = !menuOpen
    setMenuOpen(newValue)
    setDefaultMenuStatus(newValue)
  }

  const handleProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setProfileMenuAnchorEl(event.currentTarget)
  }

  const handleProfileMenuClose = () => {
    setProfileMenuAnchorEl(null)
  }

  const profile = () => {
    handleProfileMenuClose()
    navigate('/profile')
  }

  const therapist = () => {
    handleProfileMenuClose()
    navigate('/therapist')
  }

  const onLogout = async () => {
    handleProfileMenuClose()
    logout()
    navigate('/login')
  }

  const onSearch = (event: any, selected: { id: string } | null) => {
    if (!selected) return
    navigate(`/bounds/${selected.id}`)
  }

  return (
    <Box sx={{ display: 'flex', height: '100%', width: '100%', overflow: 'hidden' }}>
      <CssBaseline />
      <MuiAppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton color="inherit" aria-label="expandir menu" onClick={toggleDrawer} edge="start" sx={{ marginRight: 5 }} ><MenuIcon /></IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>Psidobem</Typography>

          {isTherapist && !isSmallScreen &&
            <Paper>
              <ClientSelect placeholder='Busca...' onChange={onSearch} value={null} sx={{ width: '300px' }} size='small' blurOnSelect />
            </Paper>
          }

          <Box>
            <IconButton size="large" aria-label="menu de perfil" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleProfileMenu} color="inherit">
              <AccountCircle />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={profileMenuAnchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={Boolean(profileMenuAnchorEl)}
              onClose={handleProfileMenuClose}
            >
              <MenuItem disabled={true} sx={{}}>{user?.name}</MenuItem>
              <Divider />
              <MenuItem onClick={profile}>
                <ListItemIcon><AccountCircle fontSize="small" /></ListItemIcon>
                Perfil
              </MenuItem>
              {(isTherapist) &&
                <MenuItem onClick={therapist}>
                  <ListItemIcon><Settings fontSize="small" /></ListItemIcon>
                  Profissional
                </MenuItem>
              }
              <MenuItem onClick={onLogout}>
                <ListItemIcon><Logout fontSize="small" /></ListItemIcon>
                Sair
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </MuiAppBar>
      <Drawer variant="permanent" open={menuOpen}>
        <DrawerHeader />
        <List sx={{overflow:'hidden'}}>
          {mainMenu
            .filter(({ onlyTherapist }) => !onlyTherapist || isTherapist)
            .map(({ icon, text, linkTo }, index) => (
              <ListItem disablePadding sx={{ display: 'block' }} key={index} title={menuOpen ? '' : text}>
                <ListItemButton component={Link} to={linkTo} sx={{ minHeight: 48, justifyContent: menuOpen ? 'initial' : 'center', px: 2.5, }}>
                  <ListItemIcon sx={{ minWidth: 0, mr: menuOpen ? 3 : 'auto', justifyContent: 'center', }}>
                    {icon}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: menuOpen ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Drawer>
      <Stack flexGrow='1' overflow='auto'>
        <DrawerHeader />
        <Box component="main" p={3} flexGrow='1' sx={{ background: '#f3f3f3', overflow: 'auto' }} >
          {props.children}
        </Box>
      </Stack>
    </Box>
  )
}

function useDefaultMenuStatus(): [boolean, (defaultStatus: boolean) => void] {
  const defaultMenuStatusStorageKey = 'default-menu-status'
  const theme = useTheme()

  let defaultMenuStatus = true

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  if (isSmallScreen) {
    defaultMenuStatus = false
  } else {
    const storedMenuStatus = localStorage.getItem(defaultMenuStatusStorageKey)
    if (storedMenuStatus !== null) {
      defaultMenuStatus = storedMenuStatus === 'true'
    }
  }

  function setDefaultMenuStatus(open: boolean) {
    localStorage.setItem(defaultMenuStatusStorageKey, open.toString())
  }

  return [defaultMenuStatus, setDefaultMenuStatus]
}
