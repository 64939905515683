import { Button, Card, Stack, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../auth/AuthProvider'
import { Center } from '../../components/Center'
import { ExpectedError, useErrorHandler } from '../../hooks/error-handler'
import { useLoginMutation } from './api'
import { PasswordField } from '../../components/PasswordField'

export function Login() {
  const { handleError } = useErrorHandler()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [login] = useLoginMutation({ email, password })
  const navigate = useNavigate()
  const location = useLocation()
  const { updateUser } = useAuth()

  const onLoginClick = async () => {
    try {
      if (!email) throw new ExpectedError('Email inválido')
      if (!password) throw new ExpectedError('Senha inválida')
      const { data } = await login()
      if (!data?.login) throw new Error('return is empty')
      updateUser(data.login.tokens.access_token, data.login.tokens.refresh_token)
      navigate(location.state?.redirectTo ?? '/')
    } catch (e) {
      handleError(e, 'Verifique seu email e senha')
    }
  }

  const onSignupClick = () => {
    navigate('/signup', { state: { redirectTo: location.state?.redirectTo } })
  }

  const onForgotPasswordClick = () => {
    navigate('/forgot-password', { state: { email } })
  }

  return (
    <Center>
      <Card sx={{ width: { xs: '90%', sm: '80%', md: 500 }, p: { xs: 2, md: 3 } }}>
        <Stack spacing={2}>
          <img src='/logo.svg' alt='logo' style={{ width: '60%', margin: '20px auto', display: 'block' }} />
          <TextField label="Email" id='email' variant="outlined" fullWidth value={email} onChange={e => setEmail(e.target.value)} onKeyDown={k => { if (k.key === 'Enter') onLoginClick() }} />
          <PasswordField label="Senha" id='password' variant="outlined" fullWidth value={password} onChange={e => setPassword(e.target.value)} onKeyDown={k => { if (k.key === 'Enter') onLoginClick() }}/>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button id='login' variant="contained" onClick={onLoginClick} fullWidth>Entrar</Button>
            <Button id='signup' variant="outlined" onClick={onSignupClick} fullWidth>Criar conta</Button>
          </Stack>
          <Typography variant="body2" textAlign="center" sx={{ mt: 2 }}>
            <Button variant="text" onClick={onForgotPasswordClick}>Esqueci minha senha</Button>
          </Typography>
        </Stack>
      </Card>
    </Center>
  )
}
