import { DataGrid, DataGridProps } from '@mui/x-data-grid'

const CustomDataGrid = (props: DataGridProps) => {
  return (
    <DataGrid
      sortingOrder={['asc', 'desc']}
      {...props}
    />
  )
}

export default CustomDataGrid
