import { Button, Card, Stack, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Center } from '../../components/Center'
import { useSnackbar } from 'notistack'
import { EmailValidator } from '../../utils/email-validator'
import { useRecoverPasswordMutation } from './ api'

export function ForgotPassword() {
  const [email, setEmail] = useState('')
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [recoverPassword, { loading }] = useRecoverPasswordMutation()

  const onSendClick = async () => {
    if (!email) {
      enqueueSnackbar('Por favor, insira um email.', { variant: 'error' })
      return
    }

    if (!EmailValidator.isValid(email)) {
      enqueueSnackbar('Por favor, insira um email válido.', { variant: 'error' })
      return
    }

    try {
      await recoverPassword({ variables: { email } })
      enqueueSnackbar(`Email de recuperação enviado para: ${email}. Verifique sua caixa de entrada.`, { variant: 'success' })
      setTimeout(() => navigate('/login', { state: { message: 'Email enviado com sucesso!' } }), 3000)
    } catch {
      enqueueSnackbar('Falha ao enviar email. Tente novamente.', { variant: 'error' })
    }
  }

  return (
    <Center>
      <Card sx={{ width: { xs: '90%', sm: '80%', md: 500 }, p: { xs: 2, md: 3 } }}>
        <Stack spacing={2}>
          <Typography variant="h6" textAlign="center">Recuperar Senha</Typography>
          <TextField
            label="Email"
            id='email'
            variant="outlined"
            fullWidth
            value={email}
            required
            onChange={e => setEmail(e.target.value)}
          />
          <Button
            id='send'
            variant="contained"
            onClick={onSendClick}
            fullWidth
            disabled={loading}
          >
            {loading ? 'Enviando...' : 'Enviar'}
          </Button>
        </Stack>
      </Card>
    </Center>
  )
}
