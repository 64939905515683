import { RouteObject, createBrowserRouter } from 'react-router-dom'
import { ProtectedRoute } from '../auth/ProtectedRoute'
import { AcceptBound } from '../pages/accept-bound/AcceptBound'
import { Bound } from '../pages/bound/Bound'
import { Bounds } from '../pages/bounds/Bounds'
import { Calendar } from '../pages/calendar/Calendar'
import { Client } from '../pages/client/Client'
import { Dashboard } from '../pages/dashboard/Dashboard'
import { AppMenu } from '../pages/home/Home'
import { Invoice } from '../pages/invoice/Invoice'
import { Invoices } from '../pages/invoices/Invoices'
import { Login } from '../pages/login/Login'
import { ForgotPassword } from '../pages/forgot-password/ForgotPassword'
import { NewInvoice } from '../pages/new-invoice/NewInvoices'
import { NotFound } from '../pages/not-found/NotFound'
import { Profile } from '../pages/profile/Profile'
import { Session } from '../pages/session/Session'
import { Signup } from '../pages/signup/Signup'
import { Therapist } from '../pages/therapist/Therapist'
import { Onboard } from '../pages/onboarding/Onboard'
import { ResetPassword } from '../pages/reset-password/ResetPassword'


type ProtectableRouteObject = RouteObject & { internal: boolean, showMenu: boolean, onlyTherapist: boolean }

const routes: ProtectableRouteObject[] = [
  {
    index: true,
    element: <Dashboard />,
    internal: true,
    showMenu: true,
    onlyTherapist: false,
  },
  {
    path: '/login',
    element: <Login />,
    internal: false,
    showMenu: false,
    onlyTherapist: false,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    internal: false,
    showMenu: false,
    onlyTherapist: false,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    internal: false,
    showMenu: false,
    onlyTherapist: false,
  },
  {
    path: '/signup',
    element: <Signup />,
    internal: false,
    showMenu: false,
    onlyTherapist: false,
  },
  {
    path: '/onboard',
    element: <Onboard />,
    internal: false,
    showMenu: false,
    onlyTherapist: false,
  },
  {
    path: '/profile',
    element: <Profile />,
    internal: true,
    showMenu: true,
    onlyTherapist: false,
  },
  {
    path: '/therapist',
    element: <Therapist />,
    internal: true,
    showMenu: true,
    onlyTherapist: false,
  },
  {
    path: '/calendar',
    element: <Calendar />,
    internal: true,
    showMenu: true,
    onlyTherapist: true,
  },
  {
    path: '/bound/accept/:token',
    element: <AcceptBound />,
    internal: true,
    showMenu: true,
    onlyTherapist: false,
  },
  {
    path: '/bounds',
    element: <Bounds />,
    internal: true,
    showMenu: true,
    onlyTherapist: true,
  },
  {
    path: '/bounds/new-client',
    element: <Client />,
    internal: true,
    showMenu: true,
    onlyTherapist: true,
  },
  {
    path: '/bounds/:id',
    element: <Bound />,
    internal: true,
    showMenu: true,
    onlyTherapist: true,
  },
  {
    path: '/bounds/:boundId/client',
    element: <Client />,
    internal: true,
    showMenu: true,
    onlyTherapist: true,
  },
  {
    path: '/bounds/:boundId/new-session',
    element: <Session />,
    internal: true,
    showMenu: true,
    onlyTherapist: true,
  },
  {
    path: '/bounds/:boundId/session/:sessionId',
    element: <Session />,
    internal: true,
    showMenu: true,
    onlyTherapist: true,
  },
  {
    path: '/invoices',
    element: <Invoices />,
    internal: true,
    showMenu: true,
    onlyTherapist: true,
  },
  {
    path: '/invoices/new',
    element: <NewInvoice />,
    internal: true,
    showMenu: true,
    onlyTherapist: true,
  },
  {
    path: '/invoices/:invoiceId',
    element: <Invoice />,
    internal: true,
    showMenu: true,
    onlyTherapist: true,
  },
  {
    path: '*',
    element: <NotFound />,
    internal: false,
    showMenu: false,
    onlyTherapist: false,
  },
]

const decorate = (route: ProtectableRouteObject) => {
  let element = route.element

  if (route.internal) {
    if (route.showMenu) {
      element = <AppMenu>{element}</AppMenu>
    }

    element = <ProtectedRoute onlyTherapist={route.onlyTherapist}>{element}</ProtectedRoute>
  }

  return { ...route, element }
}

export const router = createBrowserRouter(routes.map(decorate))
