import { ChangeEventHandler, useEffect, useState } from 'react'
import { passwordStrength } from 'check-password-strength'
import { Box, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material'
import { GppBad, RadioButtonUnchecked, TaskAlt, Verified, Visibility, VisibilityOff } from '@mui/icons-material'

const PASSWORD_MIN_LENGTH = 8

interface PasswordChecklist {
  minLength: boolean
  lowerCaseLetters: boolean
  upperCaseLetters: boolean
  numbers: boolean
  symbols: boolean
  secure: boolean
}

export interface PasswordFieldProps {
  id?: string
  variant?: 'standard' | 'outlined' | 'filled'
  label?: string
  value: string
  required?: boolean
  fullWidth?: boolean
  showRequirements?: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement> | undefined
}

export function PasswordField(props: PasswordFieldProps) {
  const { label, value, onChange, ...other } = props

  const [showPassword, setShowPassword] = useState(false)
  const [passwordChecklist, setPasswordChecklist] = useState<PasswordChecklist>()

  const handleShowPasswordClick = () => setShowPassword(show => !show)

  useEffect(() => {
    const strength = passwordStrength(value)

    const minLength = strength.length >= PASSWORD_MIN_LENGTH
    const lowerCaseLetters = strength.contains.includes('lowercase')
    const upperCaseLetters = strength.contains.includes('uppercase')
    const numbers = strength.contains.includes('number')
    const symbols = strength.contains.includes('symbol')
    const secure = minLength && lowerCaseLetters && upperCaseLetters && numbers && symbols

    setPasswordChecklist({ minLength, lowerCaseLetters, upperCaseLetters, numbers, symbols, secure })
  }, [value])

  return (
    <TextField {...other} type={showPassword ? 'text' : 'password'} label={label ?? 'Senha'} value={value} onChange={onChange} InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={handleShowPasswordClick} >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
          { props.showRequirements && (<Tooltip title={
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>{passwordChecklist?.minLength ? <TaskAlt /> : <RadioButtonUnchecked />} No mínimo {PASSWORD_MIN_LENGTH} caracteres</Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>{passwordChecklist?.lowerCaseLetters ? <TaskAlt /> : <RadioButtonUnchecked />} Letras minúsculas</Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>{passwordChecklist?.upperCaseLetters ? <TaskAlt /> : <RadioButtonUnchecked />} Letras maiúsculas</Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>{passwordChecklist?.numbers ? <TaskAlt /> : <RadioButtonUnchecked />} Números</Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>{passwordChecklist?.symbols ? <TaskAlt /> : <RadioButtonUnchecked />} Símbolos</Box>
            </Box>
          }>
            {passwordChecklist?.secure ? <Verified color='success' /> : <GppBad color='error' />}
          </Tooltip>)}
        </InputAdornment>
      ),
    }} />
  )
}

