import { gql, useMutation } from '@apollo/client'

const MUTATION_VALIDATE_RESET_TOKEN = gql`
  mutation ValidateResetToken($token: String!) {
    validateResetToken(token: $token)
  }
`

const MUTATION_RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $newPassword: String!) {
    resetPassword(token: $token, newPassword: $newPassword)
  }
`

interface ValidateResetTokenInput {
  token: string
}

interface ResetPasswordInput {
  token: string
  newPassword: string
}

interface ValidateResetTokenOutput {
  validateResetToken: boolean
}

interface ResetPasswordOutput {
  resetPassword: boolean
}

export const useValidateResetTokenMutation = (variables?: ValidateResetTokenInput) =>
  useMutation<ValidateResetTokenOutput, ValidateResetTokenInput>(MUTATION_VALIDATE_RESET_TOKEN, { variables })

export const useResetPasswordMutation = (variables?: ResetPasswordInput) =>
  useMutation<ResetPasswordOutput, ResetPasswordInput>(MUTATION_RESET_PASSWORD, { variables })
