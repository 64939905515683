import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Switch, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import { DatePicker, TimeField } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { DurationField } from '../../components/DurationField'
import { ExpectedError, useErrorHandler } from '../../hooks/error-handler'
import { concatDateTime } from '../../utils/date'
import { formatDate } from '../../utils/format'
import { BoundModel, SimpleSession, useBulkCreateAndDeleteSessionsMutation, useBulkCreateSessionsMutation } from './api'

export interface CreateSessionDialogProps {
  bound: BoundModel
  open: boolean
  onClose: () => void
  onCreate?: () => void
}

export function CreateSessionDialog(props: CreateSessionDialogProps) {
  const { handleError } = useErrorHandler()

  const guessNext = dayjs().add(1, 'hour').startOf('hour')
  const [first, setFirst] = useState<Dayjs | null>(guessNext)
  const [amount, setAmount] = useState(12)
  const [startHour, setStartHour] = useState<Dayjs | null>(guessNext)
  const [durationInMinutes, setDurationInMinutes] = useState(50)
  const [frequency, setFrequency] = useState(1)
  const [generated, setGenerated] = useState<SimpleSession[]>([])
  const [examples, setExamples] = useState<string[]>([])
  const [deleteOverlay, setDeleteOverlay] = useState(false)

  useEffect(() => {
    if (!first || !first.isValid() || !amount || !startHour || !durationInMinutes)
      return setGenerated([])

    setGenerated(generate(props.bound, first, amount, startHour, durationInMinutes, frequency))
  }, [props.bound, first, amount, startHour, durationInMinutes, frequency])

  useEffect(() => {
    const toExample = generated.map(a => formatDate(a.start))
    if (toExample.length <= 4) {
      setExamples(toExample)
    } else {
      setExamples([...toExample.slice(0, 2), '...', ...toExample.slice(-2)])
    }
  }, [generated])

  const [createSessions] = useBulkCreateSessionsMutation({ sessions: generated })
  const [createAndDeleteSessions] = useBulkCreateAndDeleteSessionsMutation({
    sessions: generated,
    boundId: props.bound.id,
    startFrom: first?.startOf('day').toDate()!,
    startTo: first ? getEndDate(first, amount, frequency).endOf('day').toDate() : new Date()
  })

  const save = async () => {
    try {
      if (!first || !amount || !startHour || !durationInMinutes || !frequency) {
        throw new ExpectedError('Preencha todos os campos')
      }

      if (deleteOverlay) {
        await createAndDeleteSessions()
      } else {
        await createSessions()
      }

      props.onCreate?.()
      props.onClose()
    } catch (e) {
      handleError(e)
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} >
      <DialogTitle>Sessões recorrentes</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} marginTop={1}>
          <Grid item xs={12} sm={4}>
            <DatePicker label='Primeira sessão' value={first} onChange={setFirst} views={['day', 'month', 'year']} sx={{ width: '100%' }} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TimeField label="Início" value={startHour} onChange={setStartHour} ampm={false} sx={{ width: '100%' }} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DurationField value={durationInMinutes} onChange={setDurationInMinutes} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField type='number' label='Quantidade de sessões' value={amount} onChange={e => setAmount(+e.target.value)} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="frequency-label">Frequência</InputLabel>
              <Select labelId="frequency-label" label="Frequência" value={frequency} onChange={e => setFrequency(+e.target.value)} >
                <MenuItem value={1}>Semanal</MenuItem>
                <MenuItem value={2}>Quinzenal</MenuItem>
                <MenuItem value={4}>Mensal</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel control={<Switch checked={deleteOverlay} onChange={e => setDeleteOverlay(e.target.checked)} />} label="Remover outras sessões no período" />
          </Grid>
          <Grid item xs={12}>
            <Stack direction='row' gap={1} component={Paper} variant='outlined' padding={1} flexWrap='wrap'>
              {examples.map((a, i) => <Chip key={i} label={a} />)}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={save}>Gerar</Button>
        <Button onClick={props.onClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  )
}

function generate(bound: BoundModel, first: Dayjs, amount: number, startTime: Dayjs, durationInMinutes: number, frequency: number) {
  const sessions: SimpleSession[] = []

  let current = concatDateTime(first, startTime)

  for (let i = 0; i < amount; i++) {
    const start = current.toDate()

    sessions.push({ boundId: bound.id, start, durationInMinutes, sessionValue: bound.defaultSessionValue })
    current = current.add(frequency, 'week')
  }

  return sessions
}

function getEndDate(first: Dayjs, amount: number, frequency: number) {
  return first.add(frequency * (amount - 1), 'week')
}
