import { gql, useMutation } from '@apollo/client'

const MUTATION_RECOVER_PASSWORD = gql`
  mutation RecoverPassword($email: String!) {
    recoverPassword(email: $email)
  }
`

interface RecoverPasswordInput {
  email: string
}

interface RecoverPasswordOutput {
  recoverPassword: boolean
}

export const useRecoverPasswordMutation = (variables?: RecoverPasswordInput) =>
  useMutation<RecoverPasswordOutput, RecoverPasswordInput>(MUTATION_RECOVER_PASSWORD, { variables })
