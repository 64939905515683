import { gql, useMutation, useQuery } from '@apollo/client'

const boundQuery = gql`
  query Bound($id: String!) {
    bound(id: $id) {
      id
      defaultSessionValue
      active
      sessionCount
      lastSession {start}
      nextSession {start}
      dueAmount
      client {name email phoneNo cpf birthDate emergencyContactName emergencyContactPhoneNo}
      sessions {id start end summary notes present sessionValue paid }
    }
  }
`

const updateBoundMutation = gql`
  mutation BoundUpdate($id: String!, $defaultSessionValue: Int, $active: Boolean) {
    boundUpdate(id: $id, data: { defaultSessionValue: $defaultSessionValue, active: $active }) {
      defaultSessionValue
      active
    }
  }
`

const updateBoundDefaultValueAndSessions = gql`
  mutation SessionBulkUpdate($boundId: String!, $startFrom: DateTime!, $defaultSessionValue: Int!) {
    boundUpdate(
      id: $boundId,
      data: { defaultSessionValue: $defaultSessionValue }
    ) {
      defaultSessionValue
    }
    sessionBulkUpdate(
        criteria: { boundId: $boundId, paid: false, start: { from: $startFrom } }
        data: { sessionValue: $defaultSessionValue }
    ) {
        id
        sessionValue
    }
  }
`

const bulkCreateSessions = gql`
  mutation SessionBulkCreate($sessions: [CreateSessionInput!]!) {
    sessionBulkCreate(data: $sessions) {
      id
    }
  }
`

const bulkCreateAndDeleteSessions = gql`
  mutation SessionBulkDeleteAndCreate($sessions: [CreateSessionInput!]!, $boundId: String!, $startFrom: DateTime!, $startTo: DateTime!) {
    sessionBulkDelete(criteria: { boundId: $boundId, present: false, paid: false, start: { from: $startFrom, to: $startTo } })
    sessionBulkCreate(data: $sessions) {
      id
    }
  }
`

export interface SessionModel {
  id: string
  start: Date
  end: Date
  summary: string
  notes: string
  present: boolean
  sessionValue: number
  paid: boolean
}

export interface BoundModel {
  id: string
  defaultSessionValue: number
  active: boolean
  sessionCount: number
  lastSession: { start: Date }
  nextSession: { start: Date }
  dueAmount: number
  client: {
    name: string
    email: string
    phoneNo: string
    cpf: string
    birthDate: Date
    emergencyContactName: string
    emergencyContactPhoneNo: string
  }
  sessions: SessionModel[]
}

export interface SimpleSession {
  boundId: string
  start: Date
  durationInMinutes: number
  sessionValue: number
}

interface BoundInput {
  id: string
}

interface BoundOutput {
  bound: BoundModel
}

interface UpdateBoundInput {
  id: string
  defaultSessionValue?: number
  active?: boolean
}

interface UpdateBoundOutput {
  boundUpdate: {
    defaultSessionValue: number
    active: boolean
  }
}

interface UpdateBoundDefaultValueAndSessionsInput {
  boundId: string
  startFrom: Date
  defaultSessionValue: number
}

interface UpdateBoundDefaultValueAndSessionsOutput {
  boundUpdate: {
    defaultSessionValue: number
  }
  sessionBulkUpdate: {
    id: string
    sessionValue: number
  }[]
}

interface SessionBulkCreateInput {
  sessions: SimpleSession[]
}

export type SessionBulkCreateOutput = {
  sessionBulkCreate: {
    id: string
  }[]
}[]

interface SessionBulkCreateAndDeleteInput {
  sessions: SimpleSession[]
  boundId: string
  startFrom: Date
  startTo: Date
}

export type SessionBulkCreateAndDeleteOutput = {
  sessionBulkCreate: {
    id: string
  }[],
  sessionBulkDelete: boolean
}[]

export const useBoundQuery = (variables?: BoundInput) => useQuery<BoundOutput, BoundInput>(boundQuery, { variables })
export const useUpdateBoundMutation = (variables?: UpdateBoundInput) => useMutation<UpdateBoundOutput, UpdateBoundInput>(updateBoundMutation, { variables })
export const useUpdateBoundDefaultValueAndSessionsMutation = () => useMutation<UpdateBoundDefaultValueAndSessionsOutput, UpdateBoundDefaultValueAndSessionsInput>(updateBoundDefaultValueAndSessions)
export const useBulkCreateSessionsMutation = (variables?: SessionBulkCreateInput) => useMutation<SessionBulkCreateOutput, SessionBulkCreateInput>(bulkCreateSessions, { variables })
export const useBulkCreateAndDeleteSessionsMutation = (variables?: SessionBulkCreateAndDeleteInput) => useMutation<SessionBulkCreateAndDeleteOutput, SessionBulkCreateAndDeleteInput>(bulkCreateAndDeleteSessions, { variables })
